<template>
  <div class="grid-container fluid">
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <h1>Job Time Usage</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-2">
        <label>Client</label>
      </div>
      <div class="cell small-12 medium-2">
        <label>Brand</label>
      </div>
      <div class="cell small-12 medium-2">
        <label>Year</label>
      </div>
      <div class="cell small-12 medium-2">
        <label>No</label>
      </div>
      <div class="cell small-12 medium-2">
        <label>Job No</label>
      </div>
      <div class="cell small-12 medium-2">
        <label>&nbsp;</label>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-1">
      <div class="cell small-12 medium-2">
        <select v-model="searchClient">
          <option
            v-for="(client, index) in clientList"
            :key="`c_${index}`"
            :value="client.id">{{ client.name }}</option>
        </select>
      </div>
      <div class="cell small-12 medium-2">
        <select v-model="searchBrand">
          <option
            v-for="(brand, index) in brandList"
            :key="`br_${index}`"
            :value="brand.id">{{ brand.name }}</option>
        </select>
      </div>
      <div class="cell small-12 medium-2">
        <select v-model="searchYear">
          <option v-for="(year, index) in years" :key="`y_${index}`">{{ year }}</option>
        </select>
      </div>
      <div class="cell small-12 medium-2">
        <input v-model="searchNumber" type="text">
      </div>
      <div class="cell small-12 medium-2">
        <input v-model="searchJobNumber" type="text">
      </div>
      <div class="cell small-12 medium-2">
        <button @click="search()" class="button expanded">Search</button>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-12">
        <table>
          <thead>
            <tr>
              <th>Job Number</th>
              <th>Description</th>
              <th>Client</th>
              <th>Brand</th>
              <th>Estimated Hours</th>
              <th>Estimated Price</th>
              <th>Actual Hours</th>
              <th>Actual Price</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(number, index) in jobNumbers" :key="`jn_${index}`">
              <td>{{ number.job_number }}</td>
              <td>{{ number.description }}</td>
              <td>{{ number.client.name }}</td>
              <td>{{ number.brand.name }}</td>
              <td>
                {{ number.estimatedCostsTotal.hours }}:{{ number.estimatedCostsTotal.minutes }}
              </td>
              <td>
                {{ number.estimatedCostsTotal.price | currency }}
              </td>
              <td>
                {{ number.actualCostsTotal.hours }}:{{ number.actualCostsTotal.minutes }}
              </td>
              <td>
                {{ number.actualCostsTotal.price | currency }}
              </td>
              <td>
                <button
                  @click="selectedJobNumber = index"
                  class="button small">View breakdown</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="selectedJobNumber >= 0" class="dialogue wide pt-2">
      <button @click="selectedJobNumber = -1" class="button small red float-right">Cancel</button>
      <div class="pt-1">&nbsp;</div>
      <table class="mt-2">
        <thead>
          <tr>
            <th>Job Number</th>
            <th>Description</th>
            <th>Client</th>
            <th>Brand</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ selectedJob.job_number }}</td>
            <td>{{ selectedJob.description }}</td>
            <td>{{ selectedJob.client.name }}</td>
            <td>{{ selectedJob.brand.name }}</td>
          </tr>
          <tr>
            <td colspan="2">
              Estimated costs<br />
              <span v-for="(cost, index) in selectedJob.estimatedCosts" :key="`ec_${index}`">
                {{ cost.cost_domain }}
                {{ cost.hours }}:{{ cost.minutes }}
                {{ cost.total | currency }}
                <br />
              </span>
            </td>
            <td colspan="2">
              Actual costs<br />
              <span v-for="(cost, index) in selectedJob.actualCosts" :key="`ec_${index}`">
                {{ cost.cost_domain }}
                {{ cost.hours }}:{{ cost.minutes }}
                {{ cost.total | currency }}
                <br />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'JobTimeUsageReport',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      clientList: [],
      brandList: [],
      jobNumbers: [],
      page: 1,
      searchBrand: 0,
      searchClient: 0,
      searchNumber: 0,
      searchYear: 23,
      searchJobNumber: '',
      selectedJob: {},
      selectedJobNumber: -1,
      years: [18, 19, 20, 21, 22, 23, 24],
    };
  },
  watch: {
    searchClient(value) {
      const client = this.clientList.filter((clientVal) => clientVal.id === value)[0];
      this.brandList = client.brands;
    },
    selectedJobNumber(value) {
      this.selectedJob = this.jobNumbers[value];
    },
  },
  methods: {
    getSearchLists() {
      axios.get(`/searches/getJobSearchList/${this.page}.json?token=${this.token}`)
        .then((response) => {
          this.clientList = response.data.clients;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    search() {
      const postData = {};
      postData.brand = this.searchBrand;
      postData.client = this.searchClient;
      postData.number = this.searchNumber;
      postData.year = this.searchYear;
      postData.jobNumber = this.searchJobNumber;
      axios.post(`/reports/getJobTimeUsage/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.jobNumbers = response.data.jobNumbers;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    this.getSearchLists();
  },
};
</script>
